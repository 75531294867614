<template>
  <div class="bottom">
       <div class="bottom-box">
      <div class="bottom-b-top">
        <img class="bottom-logo" src="https://www.carbonconf.com/zh/img/logo.529cfee1.png" alt="" />
      </div>
      <div class="bottom-b-left">
        <p class="title">{{overall.bottomtitle}}</p>
        <p v-for="(item,index) in overall.bottomlist" :key="index">
          {{item}}
        </p>
        <!-- <div class="wechat">
          <i class="iconfont icon-weixinfang"></i>
          <i class="iconfont icon-linkedin-fill"></i>
        </div> -->
        <p>{{overall.bottomgzh}}</p>
        <!-- <img class="codeimg" :src="overall.bottomgzhlogo" alt="" /> -->
        <img class="codeimg" src="https://www.carbonconf.com/zh/img/code.178da5c9.jpg">
       
      </div>
      <!-- <div class="bottom-b-mid">
        <p v-for="(item,index) in overall.bottommidlist" @click="gopage(item.path)"  :key="index">
          {{item.tname}}
        </p>
      </div> -->
      <div class="bottom-b-right">
        <p class="title">{{overall.bottomrighttitle}}</p>
        <p v-for="(item,index) in overall.bottomrighttime"  :key="index">
          {{item}}
        </p>
      </div>
      <div class="bottom-b-bottom">
         <p>{{overall.bottomleftbq}}</p>
         <p>
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">{{overall.bah}}</a>
          </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllBottom",
  methods:{
    gopage(item){
     
        this.$router.push(item)
   
    }
  }
};
</script>

<style lang="less" scoped>
.bottom {
  background: #1B1B1B;
  color: white;
  padding: 56px 0 26px 0;
  .bottom-box {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .bottom-b-top {
      width: 100%;
      .bottom-logo {
        width: 218px;
      }
    }
    .bottom-b-left {
      font-size: 14px;
      color: #F2F2F8;
      font-family: "PINGFANG-REGULAR";
      .title {
        font-weight: bold;
        font-size: 20px;
        color: white;
        font-family: "PINGFANG-HEAVY";
      }
      .wechat {
        display: flex;
        .iconfont {
          color: #908e8d;
          margin-right: 12px;
        }
      }
      .codeimg {
        width: 77px;
        margin-bottom: 20px;
      }
    }
    .bottom-b-mid{
        margin-left: -150px;
        font-weight: bold;
        color: #F2F2F8;
        font-family: "PINGFANG-BOLD";
        cursor: pointer;
        // p:nth-child(1){
        //   cursor: pointer;
        // }
    }
    .bottom-b-right {
      font-family: "PINGFANG-REGULAR";
      .title {
        font-weight: bold;
        font-size: 20px;
        font-family: "PINGFANG-HEAVY";
      }
      text-align: right;
      font-size: 14px;
    }
    .bottom-b-bottom{
        width: 100%;
        display: flex;
        font-size: 14px;
        color: #B1B1B1;
        justify-content: space-between;
        a{
        color: #B1B1B1;
        }
    }
  }
}
@media screen and (max-width: 1050px) {
  .bottom{
    padding-left: 10px;
  }
  .bottom-box{
    width: 100% !important;
  }
  .bottom-b-right{
    display: none;
  }
  .bottom-b-mid{
    display: none;
  }
  .bottom-b-bottom{
    flex-wrap: wrap;
  }
}
</style>