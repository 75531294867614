<template>
  <el-header class="header">
    <div class="navbar-header">
      <div class="header-logo">
        <div class="header-logo-wrapper" @click="goHome">
          <h1>
            <img
              alt="门票购买_论坛门票_参观票_参赛-FBIF食品饮料创新论坛"
              src="https://carbontech.obs.cn-north-4.myhuaweicloud.com/carbontechNews/image/bannerMain.png"
              class="header-logo-icon"
            />
          </h1>
          <span class="font-site-name">线上购票</span>
        </div>
        <!-- <a href="/" class="router-link-active"
          ><div class="back-home">
            <div class="el-divider el-divider--vertical">
            </div>
            <span>首页</span>
          </div></a
        > -->
      </div>
      <div class="header-right">
        <el-menu
          :default-active="this.$router.path"
          class="el-menu-demo"
          mode="horizontal"
          router
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/ticket">在线购票</el-menu-item>
          <el-menu-item index="/order">我的订单</el-menu-item>
        </el-menu>
        <div class="login-wapper">
          <el-button round @click="login" v-if="!userInfo">注册/登录</el-button>
          <el-dropdown placement="bottom" v-else>
            <el-avatar
            v-if="userInfo.avatar"
              class="user-avatar"
              :src="'https://api.dtcms.polydt.com' + userInfo.avatar"
            ></el-avatar>
            <el-avatar class="user-avatar" icon="el-icon-user-solid" v-else></el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>个人中心</el-dropdown-item>
              <el-dropdown-item>前往Carbontech官网</el-dropdown-item>
              <el-dropdown-item @click.native="logoutFn()">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </el-header>
</template>

<script>
export default {
  name: "header",
  components: {},
  data() {
    return {
      activeIndex: "1",
    };
  },
  mounted() {
    //  this.getUserInfo()
  },
  computed: {
      userInfo() {
      console.log(this.$store.state.userInfo);
      return this.$store.state.userInfo;
    },
  },
  // created(){
  //   this.getUserInfo()
  // },
  methods: {
  //  getUserInfo(){
  //   this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
  //   console.log(this.userInfo)
  //  },
    goHome() {
      this.$router.push("/");
    },
    login() {
      this.$router.push("/login");
    },
    logoutFn(){
      console.log(`11`)
      window.sessionStorage.clear();
      window.localStorage.clear()
        this.$store.commit("setUserInfo", null);
        this.$router.push("/login");
    }
    // handleSelect(index,indexPath) {
    //     console.log(index,indexPath)
    // },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  z-index: 2001;
  overflow: hidden;
  background: #fff;
  -webkit-box-shadow: 0 8px 10px 0 rgb(214 223 229 / 50%);
  box-shadow: 0 8px 10px 0 rgb(214 223 229 / 50%);
  .navbar-header {
    position: relative;
    max-width: 1200px;
    height: 70px;
    line-height: 70px;
    margin: 0 auto;

    .header-logo {
      text-align: left;
      float: left;
      display: flex;
      height: 100%;
      .header-logo-wrapper {
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;
        h1 {
          margin: 0;
          padding: 0;
          .header-logo-icon {
            width: 160px;
            vertical-align: middle;
            margin-top: -6px;
          }
        }
        .font-site-name {
          font-size: 2rem;
          font-weight: 700;
          color: #003e89;
        }
      }
    }
    .header-right {
      float: right;
      height: 100%;
      text-align: right;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .el-menu--horizontal {
        border-bottom: none;
        margin-right: 12px;
        .el-menu-item {
          //   color: #333;
          font-size: 1rem;
          border-bottom: none;
        }
      }
      .login-wapper {
        float: right;
        height: 100%;
        text-align: right;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .el-button {
          color: #003e89;
          border-color: #003e89;
          padding: 6px 10px;
          border-radius: 23px;
          .is-round {
            border-radius: 23px;
            padding: 6px 10px;
          }
        }
        .user-avatar {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          vertical-align: middle;
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .header {
    .navbar-header {
      min-width: 0;
      width: auto;
      height: 50px;
      line-height: 50px;
      .header-logo {
        display: flex;
        .header-logo-wrapper {
          h1 {
            .header-logo-icon {
              width: 80px;
              margin-top: -4px;
            }
          }

          .font-site-name {
            font-size: 16px;
          }
        }
      }
      .header-right {
        .el-menu-demo {
          display: none;
        }
      }
    }
    
  }
}
</style>