import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      // needLogin: true 
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  // 登录
  {
    path: "/login",
    name: 'login',
    component: () => import("../views/login")
  },
  // 注册
  {
    path: "/register",
    name: 'register',
    component: () => import("../views/register")
  },
  // 用户协议
  {
    path: "/userAgreement",
    name: 'userAgreement',
    component: () => import("../views/agreement/userAgreement")
  },
  // 隐私协议
  {
    path: "/privacyAgreement",
    name: 'privacyAgreement',
    component: () => import("../views/agreement/privacyAgreement")
  },
  // 门票
  {
    path: "/ticket",
    name: 'about',
    component: () => import("../views/ticket")
  },
  // 门票协议
  {
    path: "/policy",
    name: 'policy',
    component: () => import("../views/ticket/policy.vue")
  },
  // 门票
  {
    path: "/order",
    name: 'order',
    component: () => import("../views/order")
  },
  // 结算中心
  {
    path: "/checkout",
    name: 'checkout',
    component: () => import("../views/ticket/checkout")
  },
  // 结算中心-支付订单
  {
    path: "/pay",
    name: 'pay',
    component: () => import("../views/ticket/pay")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(function(to, from, next) {
  if (to.meta.needLogin) {
    //页面是否登录
    if (localStorage.getItem("token")) {
      //本地存储中是否有token(uid)数据
      next(); //表示已经登录
    } else {
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        name: "login"
      });
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});


export default router