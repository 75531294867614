<template>
  <div id="app">
     <TopHeader></TopHeader>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/ticket">About</router-link>
    </div> -->
    <router-view/>
        <AllBottom></AllBottom>
  </div>
</template>
<script>
import TopHeader from "@/components/header" //导航栏
import AllBottom from "@/components/bottom" //尾部导航栏
export default {
  name: "App",
  data() {
    return {};
  },
  components:{
    TopHeader,AllBottom
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
