export default {
    // toplogo: require("@/assets/images/home/logo.png"),
    toplogo: "https://carbontech.obs.cn-north-4.myhuaweicloud.com/carbontechNews/image/logo.529cfee1.png",
    topemailicon: "icon-bangdingyouxiang",
    topemailtext: "info@polydt.com",
    topphoneicon: "icon-a-209dianhua-yuankuang",
    topphonetext: "+86-0574-89075214",
    bottomlogo: "https://www.carbonconf.com/zh/img/logo.png",
    bottomtitle: "联系我们",
    bottomlist: ['浙江省宁波市镇海区启迪科技园A座14楼', 'Tel: +86 0574-89075214',  'Email: info@polydt.com / hubin@polydt.com'],
    bottomgzh: "关注Carbontech公众号",
    bottomgzhlogo: "https://www.carbonconf.com/zh/img/code.jpg",
    // bottommidlist: ["关于", "资讯", "观众", "展商", "会议", "赞助"],
    bottommidlist: [ 
        // { tname: "关于", path: "" },
    { tname: "资讯", path: "/exhibitiontext" },
    { tname: "观众", path: "/whyvisit" },
    { tname: "展商", path: "/onlineregistration" },
    { tname: "会议", path: "/meeting" },
    { tname: "赞助", path: "/sponsor?type=1" },
],
    bottomrighttitle: "国际碳材料大会暨产业展览会 2023 展览时间",
    bottomrighttime: ["2023年11月01日星期三 09:00 am-5:00 pm", "2023年11月02日星期四 09:00 am-5:00 pm", "2023年11月03日星期五 09:00 am-3:00 pm"],
    bottomleftbq: "宁波德泰中研信息科技有限公司 版权所有Copyright",
    bah: "2020浙ICP备17002948号-15",
    kmsj:"2023/11/01 00:00:00",
    sidebarlist: [
        // { name: "申请参展", icon: "icon-xiangyoujiantou",url:"https://oa.polydt.com/signup/files/h5t/fa61ca/index.html?codes=049a28&states=15d029" },
        // { name: "申请参会", icon: "icon-xiangyoujiantou",url:"https://oa.polydt.com/signup/files/h5t/fa61ca/index.html?codes=15d40c&states=98c23b" },
        // { name: "观众登记", icon: "icon-xiangyoujiantou",url:"https://oa.polydt.com/signup/files/h5t/fa61ca/index.html?codes=d0b5be&states=2c5812" },
        { name: "申请参展", icon: "icon-xiangyoujiantou",url:"https://www.carbonconf.com/weiye/index.html?codes=049a28&states=15d029" },
        { name: "申请参会", icon: "icon-xiangyoujiantou",url:"https://www.carbonconf.com/weiye/index.html?codes=15d40c&states=98c23b" },
        { name: "观众登记", icon: "icon-xiangyoujiantou",url:"https://www.carbonconf.com/weiye/index.html?codes=d0b5be&states=2c5812" },
    ],

}