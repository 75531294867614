<template>
  <div class="ticketList">
    <el-container>
      <el-main class="ticketList-box">
        <div class="header">
          <el-image
            src="https://carbontech.obs.cn-north-4.myhuaweicloud.com/carbontechNews/image/ticketBanner.jpg"
          ></el-image>
        </div>
        <div class="content">
          <div class="store-list">
            <div
              class="store-item"
              v-for="(item, index) in storeList"
              :key="index"
            >
              <el-image :src="item.bannerImage"></el-image>
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="text">
                  <div class="text-left">
                    <i class="el-icon-timer"></i>
                    {{ item.timer }}
                  </div>
                  <div class="text-right">
                    <i class="el-icon-location-outline"></i>
                    {{ item.location }}
                  </div>
                </div>
              </div>
              <div class="action">
                <el-button class="btn-buy" @click="goTicket(item)"
                  >立即购票</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ticketList",
  components: {},
  data() {
    return {
      storeList: [
        {
          bannerImage:
            "https://carbontech.obs.cn-north-4.myhuaweicloud.com/carbontechNews/image/luntan.png",
          title: "Carbontech2023 论坛票",
          timer: "2023年11月01-03日",
          location: "上海跨国采购会展中心",
          uuid: "QT2RAJSQG1683351740844",
          discount:"团购优惠：5-9人，9折；10人及以上，8折"
        },
         {
          bannerImage:
            "https://carbontech.obs.cn-north-4.myhuaweicloud.com/carbontechNews/image/zhanqu.png",
          title: "Carbontech2023 展区参观票",
          timer: "2023年11月01-03日",
          location: "上海跨国采购会展中心",
          uuid: "DJHX739JC1683352689754",
          discount:""
        },
         {
          bannerImage:
            "https://carbontech.obs.cn-north-4.myhuaweicloud.com/carbontechNews/image/zhanshang.png",
          title: "Carbontech2023 展商证",
          timer: "2023年11月01-03日",
          location: "上海跨国采购会展中心",
          uuid: "WRW5MQWU41683623382076",
          discount:"仅限内部注册用，请勿购买"
        },
         {
          bannerImage:
            "https://carbontech.obs.cn-north-4.myhuaweicloud.com/carbontechNews/image/jiabin.png",
          title: "Carbontech2023 嘉宾注册",
          timer: "2023年11月01-03日",
          location: "上海跨国采购会展中心",
          uuid: "WPLWVPTFB1683623593191",
           discount:"仅限内部注册用，请勿购买"
        },
     
        
      ],
      userInfo:""
    };
  },
    created(){
    this.getUserInfo()
  },
  methods: {
     getUserInfo(){
    this.userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    console.log(this.userInfo)
   },
    goTicket(item) {
      this.$router.push("/ticket");
      console.log(item.uuid)
      window.sessionStorage.setItem("ticketUuid",item.uuid)
      window.sessionStorage.setItem("activityInfo",JSON.stringify(item))

    },
//      goTicket() {
//       if(this.userInfo == null){
//         this.$router.push("/login");
//       }else{
// this.$router.push("/ticket");
//       }
      
//     },
  },
};
</script>
<style lang="less" scoped>
.ticketList-box {
  // display: flex;
  max-width: 1200px;
  margin: 12px auto;
  background: #fff;
  .header {
    // margin-top: 16px;
    // height: 270px;
    width: 100%;
  }
  .content {
    padding-top: 40px;
    text-align: right;
    margin: 0 auto;
    .store-list {
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content:space-evenly ;
      // justify-content: space-between;
      text-align: left;
      .store-item {
        width: 24%;
        margin-bottom: 20px;
        border: 1px solid #eaeaea;
        border-bottom: none;
        .img {
          width: 100%;
          padding-top: 66.66667%;
          height: 0;
          overflow: hidden;
          position: relative;
        }
        .info {
          padding: 8px;
          .title {
            line-height: 22px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .text {
            display: flex;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 16px 0 8px 0;
            color: #999;
            font-size: 0.12rem;
            .text-left {
              padding-right: 8px;
              padding-left: 2px;
              display: flex;
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: flex-start;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              i {
                margin-right: 5px;
              }
            }
            .text-right {
              padding-left: 2px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
        .action {
          width: calc(100% + 2px);
          margin: 0 -1px;
          .btn-buy {
            width: 100%;
            color: #fff;
            background: #003e89;
            border: 1px solid #003e89;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1050px) {
  .ticketList-box {
    padding: 0;
    .content {
      padding-top: 20px;
      .store-list {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-align: left;
            padding: 20px;
        .store-item {
          width: 100%;
          padding: 30px;
        }
      }
    }
  }
}
</style>
